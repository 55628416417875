html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius:6px;
  border-color:purple;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
}

::placeholder{
  color:black;
  font-weight: 500;
}

.button {
  font-weight: bold;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: purple;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}